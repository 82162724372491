html,
body {
  height: 100%;
}

body {
  position: relative;
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-align: center;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 40px;
  background: rgb(27,44,90);
  background: linear-gradient(180deg, rgba(27,44,90,1) 0%, rgba(51,73,133,1) 100%);
  font-family: 'Poppins', sans-serif;
}


/*NETPHARM*/
.text-white{
  color: #ffffff;
}
.btn-green{
  background-color: #2ab2ae;
  color: #fff;
  border-radius:90px;
  padding:10px 20px;
  transition: 0.3s;
}
.btn-green:hover{
  background-color: #FFF;
  color: #2ab2ae;
  text-decoration: none;
}
